//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";
export default {
  data() {
    return {


      form : {
        id : '',
        pk_unit_sasaran : '',
        pk_unit_sasaran_indikator : '',
        uraian : '',
        tahun : '',
        keterangan : '',
        delegasi : '',
      },

      indikator : {
        id : '',
        ku_ra_strategis : '',
        tahun : '',
        uraian : '',
        jan : 0,
        feb : 0,
        mar : 0,
        apr : 0,
        mei : 0,
        jun : 0,
        jul : 0,
        agu : 0,
        sep : 0,
        okt : 0,
        nov : 0,
        des : 0,
        satuan : '',
      },

      text : {
        sasaran_uraian : '',
        sasaran_tahun : '',
        indikator_uraian : '',
        indikator_target : '',
        indikator_sat : '',
        indikator_uraian_kuantitas : '',
        indikator_target_kuantitas : '',
        indikator_satuan_kuantitas : '',
        indikator_uraian_waktu : '',
        indikator_target_waktu : '',
        indikator_satuan_waktu : '',
      },

      id : '',
      jns_golongan_id : '',
      list_sasaran_strategis : [],
      list_data : [],
      list_indikator : [],


      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : false,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_maps_tambah : false,
      mdl_img : false,
      mdl_lihat_data : false,

      mdl_list_indikator : false,

      marginFilter : '',

      UMUM : UMUM,
    }
  },
  methods: {


    getData : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_kinerja_ku_ra_strategis + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              kelas  : 0,
              data_ke: this.page_first,
              cari_value: this.cari_value,
              delegasi : this.form.delegasi,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data;
              this.cek_load_data = false;
              // console.log(res_data);
      });
    },


    addData : function() {

      fetch(this.$store.state.url.URL_kinerja_ku_ra_strategis + "addData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form,
            indikator : this.indikator
          })
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getData();
      });
    },


    editData : function(){

      fetch(this.$store.state.url.URL_kinerja_ku_ra_strategis + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form,
            indikator : this.indikator
          })
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getData();
      });
    },

    removeData : function(idnya){
      fetch(this.$store.state.url.URL_kinerja_ku_ra_strategis + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            form : this.form,
            indikator : this.indikator
          })
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getData();
      });

    },

    selectData : function(data, data1){


        this.text.sasaran_uraian = data.uraian;
        this.text.sasaran_tahun = data.tahun;
        this.text.indikator_uraian = data1.indikator_uraian;
        this.text.indikator_target = data1.indikator_target;
        this.text.indikator_sat = data1.indikator_satuan;

        this.text.indikator_uraian_kuantitas = data1.indikator_uraian_kuantitas; 
        this.text.indikator_target_kuantitas = data1.indikator_target_kuantitas; 
        this.text.indikator_satuan_kuantitas = data1.indikator_satuan_kuantitas; 
        this.text.indikator_uraian_waktu = data1.indikator_uraian_waktu; 
        this.text.indikator_target_waktu = data1.indikator_target_waktu; 
        this.text.indikator_satuan_waktu = data1.indikator_satuan_waktu; 

        this.form.pk_unit_sasaran = data.id
        this.form.pk_unit_sasaran_indikator = data1.indikator_id

        this.indikator.pk_unit_sasaran = data.id
        this.indikator.tahun = this.form.tahun

    },


    selectDataIndikator : function(data, data1, data2){
      this.selectData(data, data1);

      // console.log("1");
      // console.log(data)
      // console.log("2");
      // console.log(data1)
      // console.log("3");
      // console.log(data2)

  
        this.form.id = data2.ra_id;
        this.form.uraian = data2.ra_uraian;

        this.indikator.id = data2.target_id;
        this.indikator.ku_ra_strategis = data2.ra_id;
        this.indikator.uraian = data2.target_uraian;
        this.indikator.jan = data2.target_jan;
        this.indikator.feb = data2.target_feb;
        this.indikator.mar = data2.target_mar;
        this.indikator.apr = data2.target_apr;
        this.indikator.mei = data2.target_mei;
        this.indikator.jun = data2.target_jun;
        this.indikator.jul = data2.target_jul;
        this.indikator.agu = data2.target_agu;
        this.indikator.sep = data2.target_sep;
        this.indikator.okt = data2.target_okt;
        this.indikator.nov = data2.target_nov;
        this.indikator.des = data2.target_des;
        this.indikator.satuan = data2.target_satuan;



        this.text.indikator_uraian = data1.indikator_uraian;
        this.text.indikator_target = data1.indikator_target;
        this.text.indikator_sat = data1.indikator_satuan;

        this.text.indikator_uraian_kuantitas = data1.indikator_uraian_kuantitas; 
        this.text.indikator_target_kuantitas = data1.indikator_target_kuantitas; 
        this.text.indikator_satuan_kuantitas = data1.indikator_satuan_kuantitas; 
        this.text.indikator_uraian_waktu = data1.indikator_uraian_waktu; 
        this.text.indikator_target_waktu = data1.indikator_target_waktu; 
        this.text.indikator_satuan_waktu = data1.indikator_satuan_waktu; 


   


    },

    jmlIndikatorRencanaAksi : function (data){
      var data = parseFloat(data.jan) + parseFloat(data.feb) + parseFloat(data.mar) + parseFloat(data.apr) + 
      parseFloat(data.mei) + parseFloat(data.jun) + parseFloat(data.jul) + parseFloat(data.agu) + 
      parseFloat(data.sep) + parseFloat(data.okt) + parseFloat(data.nov) + parseFloat(data.des);
      
      return data
    },



    // ========================================= INDIKATOR =========================================
   

    // ========================================= INDIKATOR =========================================



    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getData();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getData();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : false
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : true
            }
          }

        },

        sumery : function(){

        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    // this.$store.commit("listJeniskategorilokasi");
    // this.$store.commit("getStorage");

    if(this.$q.platform.is.mobile){
      this.marginFilter='q-x-sm'
    }
    else{
      this.marginFilter=''
    }

    const d = new Date();
    this.form.tahun = d.getFullYear();



    var get_profile = JSON.parse(localStorage.profile);
    this.form.sub_unit_kerja = get_profile.profile.unit_kerja;
    this.form.delegasi = get_profile.profile.jabatan;


    // console.log(this.form.sub_unit_kerja)
    this.getData();

  },
}
